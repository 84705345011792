<template>
    <div class="container " :class="payload.customClass">
        <nav class="-mb-px flex space-x-4 ml-6 overflow-y-auto" aria-label="Tabs">
            <a v-for="i in payload.content"
               href="javascript:;"
               :key="i.labelValue"
               :class="[i.labelValue === selectedTab.labelValue ? 'border-secondary-500 text-secondary-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'whitespace-nowrap border-b-2 py-2 px-1 text-base font-medium']"
               :aria-current="i.labelValue === selectedTab.labelValue ? 'page' : undefined"
               @click.prevent="selectedTab = i"
            >
                {{ i.labelName }}
            </a>
        </nav>

        <div class="grid md:grid-cols-3 lg:grid-cols-6 gap-4 lg:gap-10 px-5 py-5 bg-[#8881] items-end">
            <template v-for="item in selectedTab?.options">
                <div class="flex-1" v-if="item.inputType === 'checkbox' || item.inputType === 'select'">
                    <FormSelect
                            v-model="query[item.queryName]"
                            :label="item.attributeName"
                            :options="item.options"
                            input-value="id"
                            input-label="label"
                    >
                    </FormSelect>
                </div>
            </template>

            <div class="flex-1">
                <FormButton color="primary" class="w-full lg:w-auto" @click.prevent="navigateTo(redirectLink)">
                  {{$t('app.filter')}}
                </FormButton>
            </div>
        </div>
    </div>

</template>

<script setup>

import collect from "collect.js";

const props = defineProps(['payload'])
const defaultCategoryId = ref(props.payload?.config?.defaultCategory)
const selectedTab = ref({})
const query = ref({})

onMounted(() => {
    selectedTab.value = collect(props.payload?.content).where('labelValue', defaultCategoryId.value).first()
})

const redirectLink = computed(() => {
    return {
        path: selectedTab.value?.urlSlug,
        query: query.value
    }
})
</script>

<style scoped>

</style>